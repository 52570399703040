import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Alert  } from "react-bootstrap"
import Loader from '../components/loading';

import logo from '../images/Logo300x140.png'; 
import { isValidEmail, isValidPhoneNumber } from "../helpers/string";
import constants from '../helpers/constants';
import Fetch from "../helpers/fetch";
import { navigate } from 'gatsby';

const RegisterPage = ({location, ...props}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false)
  const [custName, setCustName] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailInvalidMsg, setEmailInvalidMsg] = useState('Email address is invalid')
  const [failMsg, setFailMsg] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [firstTimeDiscount, setFirstTimeDiscount] = useState(0)
  const [haveReferralCode, setHaveReferralCode] = useState(false)

  const getUpline = () => {
    if (!props.referralCode) {
      setShow(true);
      return;
    }
    
    setHaveReferralCode(true)
    const url=`${constants.BACKEND_URL}/publicCustomers/ref/${props.referralCode}`
    Fetch.get(url).then(data => {
      setCustName(data.name)
      getFirstTimeDiscount()
    })
  }

  const getFirstTimeDiscount = () => {
    const url=`${constants.BACKEND_URL}/settings/activateAccount`
    Fetch.get(url).then(res => {
      if (!res && !res.data) return;
      setFirstTimeDiscount(res.data.value)
      setShow(true)
    })
  }

  const checkEmailValidity = (e) => {
    const emailElement = document.querySelector("#newUserEmail");
  
    emailElement.setCustomValidity(''); //trigger valid state
    let isEmailValid = false;
  
    if (emailElement && emailElement.value) {
      isEmailValid = isValidEmail(emailElement.value);
    }
  
    if (!isEmailValid) {
      emailElement.setCustomValidity('Set any text here to trigger invalid state');
    }
    
    return isEmailValid;
  }

  const checkPhoneNumberValidity = (e) => {
    const phoneNumberElement = document.querySelector("#newUserPhoneNumber");
  
    phoneNumberElement.setCustomValidity(''); //trigger valid state
    let isPhoneNumberValid = false;
  
    if (phoneNumberElement && phoneNumberElement.value) {
      isPhoneNumberValid = isValidPhoneNumber(phoneNumberElement.value);
    }
  
    if (!isPhoneNumberValid) {
      phoneNumberElement.setCustomValidity('Set any text here to trigger invalid state');
    }
    
    return isPhoneNumberValid;
  }

  const createUser = () => {
    setShowLoader(true)
    const url=`${constants.BACKEND_URL}/publicCustomers/register`
    const data={
      name,
      phone,
      email,
      referralCode: props.referralCode
    }
    Fetch.post(url, JSON.stringify(data)).then(res => {
      if (res.error) {
        setShowFailAlert(true);
        setFailMsg(res.message ? res.message : res.error);
        setShowLoader(false)
        return;
      }

     setShowLoader(false)
     navigate('/login', {
       state : {
        newUserRegistration: true,
       }
     });

    })
  }

  useEffect(() => {
    getUpline()
    return () => {
    }
  }, [])

  const handleSubmit = (event) => {
    setShowFailAlert(false);
    event.preventDefault();
    checkEmailValidity();
    checkPhoneNumberValidity();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    createUser()
    // saveEditItem();
  };

  const handleInputChange = (e) => {
    const {property} = e.target.dataset;
    const value = e.target.value;
    switch (property) {
      case 'name':
        setName(value);
        break;
      case 'phone':
        setPhone(value);
        if (validated) {
          checkPhoneNumberValidity();
        }
        break;
      case 'email':
        setEmail(value);
        if (validated) {
          checkEmailValidity();
        }
        break;
      default:
        break;
    }
  }

  return (
    show ? (
      <>
        
        <div className="invite">
          <Loader show={showLoader}></Loader>

          <div id="leftImage">
          </div>
          <div id="registration">
            <Container className="text-center container">
              <Row className="mb-3">
                <Col><img className="logo" src={logo} /></Col>
              </Row>
              <Row>
                <Col>
                  <div className="infoText mb-3">{haveReferralCode ? `${custName} has given you S$${firstTimeDiscount} off towards your first purchase`: `Register with us and enjoy a premium service for your clothing needs`}</div>
                </Col>
              </Row>
              <Alert variant="danger" show={showFailAlert} dismissible>{failMsg}</Alert>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="my-3">
                  <Form.Control 
                    required 
                    size="lg" 
                    type="text" 
                    placeholder="Your Name" 
                    data-property="name" 
                    value={name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control 
                    required
                    id="newUserPhoneNumber" 
                    size="lg" 
                    type="text" 
                    placeholder="Your Phone Number"
                    data-property="phone"
                    value={phone}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid" className="text-left">Phone Number is invalid</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    required
                    id="newUserEmail" 
                    size="lg" 
                    type="text" 
                    placeholder="Your Email Address" 
                    data-property="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid" className="text-left">{emailInvalidMsg}</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" size="lg" style={{width:"100%"}}>{haveReferralCode ? `Claim your S${firstTimeDiscount} off for your first purchase` : `Register`}</Button>
              </Form>
              <hr/>
              By signing up, you agree to our <u><a href="http://reddotbespoke.com/pdpa/pdpa.pdf" target="_blank">PDPA Consent</a></u> and <u><a href="http://reddotbespoke.com/privacy-policy/privacy-policy.pdf" target="_blank">Privacy Policy</a></u>
            </Container>

          </div>
        </div>
        {/* <div><pre>{JSON.stringify(location, null, 2) }</pre></div> */}
      </>
    ) : <>Please wait as we verify the referral code</>
  )
}

export default RegisterPage