import React from 'react';
import { Router } from "@reach/router"

import RegisterPage from './register'
import Default from "../components/default"

const Invite = ({location}) => {
  return (
    <Router basepath="/invite">
      <RegisterPage path="/u/:referralCode"/>
      <RegisterPage path="/u"/>
      <RegisterPage path="/" />
    </Router>
  )

  
}

export default Invite