import React from 'react';

const Default = () => {

  return (
    <>
    Default component
    </>
  )
}

export default Default