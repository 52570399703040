const replacerRegex = /([A-Z])/g
const upperCaserRegex = /^.{0,1}/
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneNumberSGRegex = /^(\+65|65)*(6|8|9)[0-9]{7}$/

const replacer = (match) => {
  return ` ${match}`;
}
const upperCaser = (match) => {
  return match.toUpperCase();
}

const isValidEmail = (emailAddress) => {
  const emailArray = emailAddress.split(",");
  if (emailArray.length <= 0) {
    return false;
  }
  let overallValidity = true;
  emailArray.forEach(email => {
    if (!emailRegex.test(email.trim())) {
      overallValidity = false;
    }
  });

  return overallValidity;
}

const isValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumberSGRegex.test(phoneNumber)) {
    return false;
  }
  return true
}

const replaceSpaceInPhoneNumber = (phoneNumber) => {
  let text = phoneNumber;
  const regExp = new RegExp(/\s/g);
  const haveSpace = regExp.test(text);
  
  if (haveSpace) {
    text = text.replace(regExp, '');
  }

  return text;
}



export {replacerRegex, upperCaserRegex, emailRegex, isValidEmail, isValidPhoneNumber, replacer, upperCaser, replaceSpaceInPhoneNumber}
